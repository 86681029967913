<!-- eslint-disable vue/no-v-html -->
<template>
	<w-dialog
		v-model="dialog"
		max-width="700px"
		:title="$t('user.titles.update_title', { firstname: currentAccountant.firstname, lastname: currentAccountant.lastname })"
		@close="$emit('close')"
	>
		<w-flex shrink>
			<w-search-input v-model="search" pr-2 :placeholder="$t('actions.search_company')" />
		</w-flex>
		<w-flex shrink scroll-y ml-4 mr-4>
			<w-layout justify-center row>
				<v-flex scroll-y>
					<v-data-table
						class="elevation-1"
						:headers="tableHeaders"
						:items="vendors || []"
						:no-data-text="noDataText"
						:loading="loading"
						:pagination.sync="pagination"
						:total-items="pagination.totalItems"
						:rows-per-page-items="rowsPerPageItems"
					>
						<template v-slot:headers="{ headers }">
							<tr>
								<th
									v-for="(header, index) in headers"
									:key="index"
									:class="{
										active: pagination.sortBy == header.value,
										column: true,
										sortable: false,
										'text-xs-center': header.align == 'center',
										'text-xs-left': header.align != 'center'
									}"
								>
									{{ header.text }}
								</th>
							</tr>
						</template>
						<template v-slot:items="{ item: vendor, index }">
							<tr>
								<td v-html="$highlightMatching(vendor.company, search)" />
								<td>
									<v-switch
										v-model="vendor.access"
										:disabled="loadingStates[index].access"
										:loading="loadingStates[index].access"
										class="ma-0 pa-0"
										hide-details
										color="success"
										@change="onChangeAccess(vendor, index)"
									/>
								</td>
								<td>
									<SelectWithChips
										v-if="vendor.access"
										v-model="vendor.themes"
										class="ma-0 pa-0"
										:disabled="loadingStates[index].themes"
										:items="themes[index] || []"
										label="Ajouter"
										:loading="loadingStates[index].themes"
										hide-details
										multiple
										:item-clearable="false"
										item-text="title"
										item-value="id"
										single-line
										@blur="onChangeThemes(vendor, index)"
									/>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
			</w-layout>
		</w-flex>
	</w-dialog>
</template>

<script>
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'TeamManagerDialogUpdate',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [TeamManagerModuleGuard],
	props: {
		accountant: {
			required: true,
			type: Object
		},
		accountingFirm: {
			required: true,
			type: Object
		},
		clients: {
			required: true,
			type: Array
		},
		roles: {
			required: true,
			type: Array
		},
		isAccountantAdmin: {
			required: true,
			type: Boolean
		},
		dialogUpdate: {
			required: true,
			type: Boolean
		},
		user: {
			required: true,
			type: Object
		}
	},
	data () {
		return {
			dialog: false,
			currentAccountant: {},
			loadingStates: [],
			vendors: [],
			search: '',
			loading: false,
			activeThemes: [],
			rowsPerPageItems: [10, 20, 50, 100],
			tableHeaders: [
				{ align: 'left', text: this.$t('company.companies'), value: 'company' },
				{ align: 'left', text: this.$t('themes.team-manager.details.access'), value: 'access' },
				{ align: 'left', text: this.$t('themes.team-manager.details.themes_title'), value: 'themes' }
			],
			pagination: {
				page: 1,
				rowsPerPage: 10,
				totalItems: 0,
				sortBy: 'company',
				descending: false
			},
		}
	},
	computed: {
		themes: function () {
			if (this.vendors) {
				return this.vendors.map(vendor => {
					const client = this.clients.find(c => c.id == vendor.id)
					if (!client) {
						return []
					}
					return client.themes
				})
			} else {
				return []
			}
		},
		noDataText: function () {
			let result = null
			if (this.loading) {
				result = this.$t('customers.loading-progress')
			} else if (this.search) {
				result = this.$t('customers.no-data-found-for-search', { search: this.search })
			} else {
				result = this.$t('customers.no-data-found')
			}
			return result
		},
	},
	watch: {
		currentAccountant: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		dialogUpdate: function (val) {
			if (val) {
				this.pagination.page = 1
				this.pagination.search = ''
				this.init(this.accountant, true)
			}
		},
		pagination: {
			deep: true,
			handler: function (newVal, oldVal) {
				if (oldVal && newVal && (newVal.page !== oldVal.page || newVal.rowsPerPage !== oldVal.rowsPerPage)) {
					this.init(this.accountant)
				}
			},
			immediate: true
		},
		search: {
			handler: function (newVal, oldVal) {
				if ((!oldVal && newVal) || (oldVal && newVal) || (oldVal && !newVal)) {
					this.pagination.page = 1
					this.init(this.accountant)
				}
			}
		},
	},
	methods: {
		init: function (accountant, isOpeningDialog = false) {
			if (this.clients.length === 0) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('company.snackerror.no_company'))
			} else if (!this.isAccountantAdmin) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('company.snackerror.not_admin'))
			} else {
				let params = {
					page: this.pagination.page,
					rowsPerPage: this.pagination.rowsPerPage,
					q: this.search,
				}
				isOpeningDialog ? this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true) : this.loading = true
				
				this.currentAccountant = accountant
				this.service
					.getUserClients(this.accountingFirm.id, this.currentAccountant, params)
					.then(res => {
						if (typeof res.data === 'object') {
							// handle when res is like { "0": {...}, "1": {...}, ...}
							// can't simply transform in array with Array.from
							this.vendors = Object.keys(res.data).map(_ => res.data[_])
						} else {
							this.vendors = res.data
						}
						this.loadingStates = this.vendors.map(() => ({
							access: false,
							themes: false
						}))
						this.dialog = true

						this.activeThemes = this.vendors.map(client => client.themes.map(t => t.id))
						this.pagination.page = res.pagination.currentPage
						this.pagination.rowsPerPage = res.pagination.perPage
						this.pagination.totalItems = res.pagination.total
					})
					.finally(() => {
						isOpeningDialog ? this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false) : this.loading = false
					})
			}
		},
		updateAccountantAccess: function (company, index) {
			let data = {
				accounting_firm_id: this.accountingFirm.id,
				company_id: company.id,
				access: company.access
			}
			return this.service
				.updateAccountantAccess(this.accountingFirm.id, this.currentAccountant, data)
				.then(() => {
					if (company.access) {
						let params = {
							page: this.pagination.page,
							rowsPerPage: this.pagination.rowsPerPage,
						}
						setTimeout(() => {
							return this.service
								.getUserClients(this.accountingFirm.id, this.currentAccountant, params)
								.then(res => {
									if (typeof res.data === 'object') {
										// handle when res is like { "0": {...}, "1": {...}, ...}
										// can't simply transform in array with Array.from
										this.vendors = Object.keys(res.data).map(_ => res.data[_])
									} else {
										this.vendors = res.data
									}
									this.activeThemes = this.vendors.map(client => client.themes.map(t => t.id))
									company.themes = this.activeThemes[index]
								})
								.finally(() => {
									this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('accounting_firms.messages.right_access_updated'), 0)
									this.loadingStates[index].themes = false
								})
						}, 4000)
					}
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('accounting_firms.messages.right_access_updated'), 1)
				})
		},
		updateAccountantThemes: function (company, index) {
			if (!company.access) {
				throw new Error("The current user doesn't have access to the selected company")
			}
			let data = {
				accounting_firm_id: this.accountingFirm.id,
				company_id: company.id,
				access: company.access
			}
			let companyThemes
			if (typeof company.themes[0] == 'number') {
				companyThemes = company.themes
			} else {
				companyThemes = company.themes.map(t => t.id)
			}
			const oldVal = this.activeThemes[index]
			let val = companyThemes
			let diff
			if (oldVal) {
				diff = [...val.filter(x => !oldVal.includes(x)), ...oldVal.filter(x => !val.includes(x))]
			} else {
				diff = [...val]
			}
			data.themes = diff
			this.activeThemes[index] = companyThemes

			if (data.themes.length != 0) {
				return this.service.updateAccountantThemes(this.accountingFirm.id, this.currentAccountant, data).then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('accounting_firms.messages.themes_updated', data.themes.length))
				})
			} else {
				return Promise.resolve()
			}
		},
		onChangeAccess: function (company, index) {
			this.loadingStates[index].access = true
			this.loadingStates[index].themes = true
			this.updateAccountantAccess(company, index).finally(() => {
				this.loadingStates[index].access = false
			})
		},
		onChangeThemes: function (company, index) {
			this.loadingStates[index].themes = true
			this.updateAccountantThemes(company, index).finally(() => {
				this.loadingStates[index].themes = false
			})
		}
	}
}
</script>
