var render = function render(){var _vm=this,_c=_vm._self._c;return _c('w-dialog',{attrs:{"max-width":"700px","title":_vm.$t('user.titles.update_title', { firstname: _vm.currentAccountant.firstname, lastname: _vm.currentAccountant.lastname })},on:{"close":function($event){return _vm.$emit('close')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('w-flex',{attrs:{"shrink":""}},[_c('w-search-input',{attrs:{"pr-2":"","placeholder":_vm.$t('actions.search_company')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('w-flex',{attrs:{"shrink":"","scroll-y":"","ml-4":"","mr-4":""}},[_c('w-layout',{attrs:{"justify-center":"","row":""}},[_c('v-flex',{attrs:{"scroll-y":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.vendors || [],"no-data-text":_vm.noDataText,"loading":_vm.loading,"pagination":_vm.pagination,"total-items":_vm.pagination.totalItems,"rows-per-page-items":_vm.rowsPerPageItems},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function({ headers }){return [_c('tr',_vm._l((headers),function(header,index){return _c('th',{key:index,class:{
									active: _vm.pagination.sortBy == header.value,
									column: true,
									sortable: false,
									'text-xs-center': header.align == 'center',
									'text-xs-left': header.align != 'center'
								}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]}},{key:"items",fn:function({ item: vendor, index }){return [_c('tr',[_c('td',{domProps:{"innerHTML":_vm._s(_vm.$highlightMatching(vendor.company, _vm.search))}}),_c('td',[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.loadingStates[index].access,"loading":_vm.loadingStates[index].access,"hide-details":"","color":"success"},on:{"change":function($event){return _vm.onChangeAccess(vendor, index)}},model:{value:(vendor.access),callback:function ($$v) {_vm.$set(vendor, "access", $$v)},expression:"vendor.access"}})],1),_c('td',[(vendor.access)?_c('SelectWithChips',{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.loadingStates[index].themes,"items":_vm.themes[index] || [],"label":"Ajouter","loading":_vm.loadingStates[index].themes,"hide-details":"","multiple":"","item-clearable":false,"item-text":"title","item-value":"id","single-line":""},on:{"blur":function($event){return _vm.onChangeThemes(vendor, index)}},model:{value:(vendor.themes),callback:function ($$v) {_vm.$set(vendor, "themes", $$v)},expression:"vendor.themes"}}):_vm._e()],1)])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }